import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    { window.location.href="https://interno.gruposerex.com/intranet/procet_login.php?email="+accounts[0].username+"&token="+accounts[0].localAccountId} 
    function RequestProfileData() {
        
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }
     

     
    return (
        <>
             <h5 className="card-title">Bienvenido {accounts[0].name}</h5> 

          
            <br/>
            
            <br/>
            <br/>
            
        </>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {  
    function Registrar(){
        window.location.href="https://interno.gruposerex.com/intranet/Admin/Nomina/Registro.php";
    } 
    return (
        <div className="App">
             <Button variant="secondary" onClick={() =>Registrar()}>Regístrate</Button>
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
           
                <h5 className="card-title"></h5>
                
            </UnauthenticatedTemplate>
           
           
        </div>
    );
};

export default function App() {
 
    return (
       
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
